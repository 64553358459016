import React,{useState} from 'react'
import '../modal.scss'

const Modal = ({onCloase,info}) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const onemailChange = (value)=>{
    setEmail(value)
  }
  const onphoneChange = (value)=>{
    setPhone(value)
  }
  return (
    <div className="modal">
        <div className='content'>
          <div className='cloase'><span onClick={onCloase}>X</span></div>
          <div className="content_main">
            { info?.info.map((item)=>{
             return <p>{item}</p>
            })}
            <input type='mail' placeholder='E-mail' onChange={(e)=>onemailChange(e.target.value)} />
            <input type='phone' placeholder='Phone' onChange={(e)=>onphoneChange(e.target.value)} />
            <button className = 'button'
            disabled={!email || !phone}
             onClick = {
                () => {
              setEmail('')
              setPhone('')
              onCloase()
              }}>Отправить</button>
          </div>
        </div>
    </div>
  )
}

export default Modal