import React from 'react'
import '../TopProducts.scss'
import Timer from './Timer'
import img1 from '../assets/images/493405_1.webp'
import img2 from '../assets/images/493224_1.jpg'
const TopProducts = () => {
  return (
    <div className='row'>
        <div className="item">
            <div className="item_img" >
                <img src={img1} alt="img1" />
            </div>
            <div className="item_info">
                <h2>Samsung Galaxy S23 FE 5G</h2>
                <div className="star_section" >
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="text">851 отзыва</span>
                </div>
                <div className="price">
                    <h2>39 999 ₽</h2>
                    <del>44 000 ₽</del>
                    <div className="skit">-9%</div>
                </div>
                <div className = "timer" >
                    <Timer />
                </div>
            </div>
        </div>
        <div className="item">
            <div className="item_img" >
                <img src={img2} alt="img1" />
            </div>
            <div className="item_info">
                <h2>POCO X6 Pro 8/256</h2>
                <div className="star_section" >
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="text">351 отзыва</span>
                </div>
                <div className="price">
                    <h2>21 105 ₽</h2>
                    <del>24 000 ₽</del>
                    <div className="skit">-12%</div>
                </div>
                <div className = "timer" >
                    <Timer />
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopProducts