import React from 'react'
import './App.scss'
import Main from './components/main_home/Main'

const App = () => {
  return (
    <div>
      <Main/>
    </div>
  )
}

export default App