import React, { useState, useEffect } from 'react';

const Timer = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const dayOfMonth = 7; 
    let targetDate;

    if (now.getDate() < dayOfMonth) {
      targetDate = new Date(now.getFullYear(), now.getMonth(), dayOfMonth);
    } else {
      targetDate = new Date(now.getFullYear(), now.getMonth() + 1, 6);
    }

    const difference = targetDate.getTime() - now.getTime();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const translations = {
    hours: 'часов',
    minutes: 'минут',
    seconds: 'секунд',
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, index) => {
    if (timeLeft[interval] === undefined) {
      return;
    }

    timerComponents.push(
      <div key={interval} className='date_container'>
        <div className='date_box'>
          <div>{timeLeft[interval]}</div>
          <div className="name">{translations[interval]}</div>
        </div>
        {/* Add a separator only if it's not the last component */}
        {index < Object.keys(timeLeft).length - 1 && <h1>:</h1>}
      </div>
    );
  });

  return (
    <div>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
};

export default Timer;
