import React from 'react';
import '../footer.scss'; // Optional: Import a CSS file for styling
import logo from '../assets/images/phoneLogo(1).png'
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo">
      <img src={logo} />
</div>
        <div className="contact-info">
          <h3>Контактный номер:</h3>
          <p>89966442798</p>
        </div>
        <div className="working-hours">
          <h3>По будням:</h3>
          <p>с 11:00 до 18:00</p>
        </div>
        <div className="working-hours">
          <h3>По выходным:</h3>
          <p>с 11:00 до 16:00</p>
        </div>
        <div className="management-info">
          <h3>Владелец:</h3>
          <p>ИП Гаврилов Роман Сергеевич</p>
        </div>
      </div>
    
    </footer>
  );
};

export default Footer;