import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '../slader.scss';
import {
  Autoplay
} from 'swiper/modules';

const Slider2 = () => {
  const [data] = useState([
    {
      name: 'Мобильный телефон Realme Note 50',
        desc: 'Заклятый конкурент Xiaomi начал продавать в России Realme Note 50 и это офигеть просто за 6 тысяч то. Телефон который удивил и своей шустростью за такие то деньжищЩща, и способностями камеры. Расскажу про фоторежим Pro'
    },
    {
      name: 'Смартфон Xiaomi Redmi Note 9 Pro',
        desc: 'Xiaomi Redmi Note 9 Pro/128GB собрал в себе лучшие качества смартфонов: емкая батарея, классная камера, большой объем встроенной памяти, NFC и очень удобное расположение отпечатка разблокировки. О плюсах и минусах за 2,5 месяца использования, фото и видео'
    },
    {
      name: 'Смартфон Xiaomi Redmi note 13 pro',
        desc: 'Весьма симпатичный и достаточно крепкий "середнячок"'
    },
    {
      name: 'Смартфон Realme 12 Pro+',
        desc: 'Мой новый смартфон Realme 12 Pro+. Искала телефон с хорошей камерой в пределах 30 тыс. - получила стильный и функциональный гаджет :) Сравнение с Poco F5'
    },
  ])
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        modules={[Autoplay]}
        loop={true}
        autoplay={
          {
            delay: 2500,
            disableOnInteraction: false,
          }
        }
        className="mySwiper"
      >
        {
          data.map((item) => {
            return (
              <SwiperSlide>
                <div className='swager_item_bg swager2' >
                    <h1>{item.name}</h1>
                    <div className="star_section" >
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                </div>
                <p>
                    {item.desc}
                </p>
                </div>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  );
}


export default Slider2