import React, { useState } from 'react';
import './Main.scss'
import Slider1 from '../Slider1'
import TopProducts from '../TopProducts'
import Card from '../../card/Card';
import DynamicTitle from '../../dynamicTitle/DynamicTitle';
import Footer from '../Fotter'
import Modal from '../Modal'

import img1 from "../../assets/images/14pro.jpg"
import img2 from "../../assets/images/23.webp"
import img3 from "../../assets/images/google.png"
import img4 from "../../assets/images/green-img.png"
import img5 from "../../assets/images/xiaomi_redmi_12_4gb128gb_sky_blue_1.webp"
import img6 from "../../assets/images/PDX-223_black_v01-1.webp"
import img7 from "../../assets/images/428_428_A378AA4E3054ABBDC666E9B2135C75B2mp.png"
import img8 from "../../assets/images/Motorola-edge30Ultra-pdp-render-ClarkWhite-8-pjk3y20a.png"
import img9 from "../../assets/images/Untitled-1-1024x1024.png"

import Slider2 from '../Slider2'
import mobile123 from '../../assets/images/mobile123.png'

import logo from '../../assets/images/phoneLogo(1).png'
const Main = () => {
  const imgData = [{
      id: 1,
      model: "iPhone 14 Pro",
      price: "94,905",
      img: img1,
      desc: "Последняя модель iPhone с передовыми функциями, такими как ProMotion, чип A15 Bionic и потрясающий дисплей Super Retina XDR",
      info: [
        'Дисплей: 6.1 " OLED - 1179 x 2556 ',
        'Чип: Apple A16 Bionic',
        'Камера: 4(48 MP + 12 MP + 12 MP)',
        'Батарея: 3200 мАч',
        'OS: iOS 17.5',
        'Вес: 206 г.'
      ]
    },
    {
      id: 2,
      model: "Samsung Galaxy S23",
      price: "85,405",
      img: img2,
      desc: "Высокопроизводительный Android-смартфон с элегантным дизайном, мощным процессором и впечатляющей камерой.",
      info: [`Дисплей: 6.1" Dynamic AMOLED - 1080 x 2340 `,
        `Чип: Snapdragon 8 Gen 2 Mobile Platform for Galaxy `,
        `Камера: 3 (50 MP + 10 MP + 12 MP) `,
        `Батарея: 3900 мАч `,
        `OS: Android 14 `,
        `Вес: 168 г.`
      ]
    },
    {
      id: 3,
      model: "Google Pixel 7",
      price: "75,905",
      img: img3,
      desc: "Умные функции камеры и чистый Android, работающий на новейшем чипе Google Tensor",
      info: [
        'Дисплей: 6.3 " AMOLED - 1080 x 2400 ',
        'Чип: Google Tensor G2',
        'Камера: 2(50 MP + 12 MP)',
        'Батарея: 4355 мАч',
        'OS: Android 14',
        'Вес: 197 г.'
      ]
    },
    {
      id: 4,
      model: "OnePlus 11",
      price: "71,155",
      img: img4,
      desc: "Быстрый и плавный опыт благодаря Snapdragon 8 Gen 2, AMOLED-дисплею и технологии Warp Charge",
      info: [
        'Дисплей: 6.7 " AMOLED - 1440 x 3216 ',
        'Чип: Qualcomm Snapdragon 8 Gen 2',
        'Камера: 3(50 MP + 32 MP + 48 MP)',
        'Батарея: 5000 мАч',
        'OS: Android 14',
        'Вес: 205 г.'
      ]
    },
    {
      id: 5,
      model: "Xiaomi Mi 12",
      price: "66,705",
      img: img5,
      desc: "Смартфон с 108 МП камерой, ярким AMOLED-дисплеем и длительным временем автономной работы",
      info: [
        'Дисплей: 6.28 " AMOLED - 1080 x 2400 ',
        'Чип: Qualcomm Snapdragon 8 Gen 1',
        'Камера: 3(50 MP + 13 MP + 5 MP)',
        'Батарея: 4500 мАч',
        'OS: Android 13',
        'Вес: 180 г.'
      ]
    },
    {
      id: 6,
      model: "Sony Xperia 1 IV",
      price: "89,155",
      img: img6,
      desc: "Премиум-смартфон с 4K HDR OLED-дисплеем, продвинутыми функциями фотографии и высококачественным звуком",
      info: [
        'Дисплей: 6.5 " OLED - 1644 x 3840 ',
        'Чип: Qualcomm Snapdragon 8 Gen 1',
        'Камера: 4(12 MP + 12 MP + 12 MP + 0.3 MP)',
        'Батарея: 5000 мАч',
        'OS: Android 13',
        'Вес: 185 г.'
      ]
    },
    {
      id: 7,
      model: "Huawei P50 Pro",
      price: "85,405",
      img: img7,
      desc: "Стильный дизайн, отличные камеры и мощная производительность в тонком корпусе",
      info: [
        'Дисплей: 6.6 " OLED - 1228 x 2700 ',
        'Чип: Qualcomm Snapdragon 888 4 G',
        'Камера: 4(50 MP + 64 MP + 13 MP + 40 MP)',
        'Батарея: 4360 мАч',
        'OS: Android 11',
        'Вес: 195 г.'
      ]
    },
    {
      id: 8,
      model: "Motorola Edge 30 Ultra",
      price: "75,905",
      img: img8,
      desc: "Флагманская производительность с 200 МП основной камерой, быстрой зарядкой и плавным дисплеем с частотой 144 Гц",
      info: [
        'Дисплей: 6.67 " OLED - 1080 x 2400 ',
        'Чип: Qualcomm Snapdragon 8 Plus Gen 1',
        'Камера: 3(200 MP + 12 MP + 50 MP)',
        'Батарея: 4610 мАч',
        'OS: Android 12',
        'Вес: 198.5 г.'
      ]
    },
    {
      id: 9,
      model: "Asus ROG Phone 7",
      price: "94,905",
      img: img9,
      desc: "Игровой монстр с дисплеем 165 Гц, топовой производительностью и большим аккумулятором для длительной игры",
      info: [
        'Дисплей: 6.78 " AMOLED - 1080 x 2448 ',
        'Чип: Qualcomm Snapdragon 8 Gen 2',
        'Камера: 3(50 MP + 13 MP + 5 MP)',
        'Батарея: 6000 мАч',
        'OS: Android 14',
        'Вес: 239 г.'
      ]
    },
  ]

  const [selectedItem, setSelectedItem] = useState()
  const [open, setOpen] = useState()

  const onSelectItem = (id) => {
    setSelectedItem(imgData.find((elm) => elm.id === id))
    setOpen(true)
  }
  const onCloase = (id) => {
    setOpen(false)
  }
  return (
    <div className='main'>
      <header>
        <nav>
          <img className="logo" src={logo} />
          <ul>
            <li>
              <a href="#product">Товары дня</a>
            </li>  
            <li>
              <a href="#category">Категории товаров</a>
            </li>  
            <li>
              <a href="#obaut">О Нас</a>
              </li>  
            <li>
              <a href="#rew">Отзывы</a>
              </li>  
          </ul>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone"><path d="M27.308,20.649l-2.2-2.2a3.521,3.521,0,0,0-4.938-.021,2.152,2.152,0,0,1-2.729.267A15.026,15.026,0,0,1,13.3,14.562a2.181,2.181,0,0,1,.284-2.739A3.521,3.521,0,0,0,13.553,6.9l-2.2-2.2a3.514,3.514,0,0,0-4.961,0l-.633.634c-3.3,3.3-3.053,10.238,3.813,17.1,4.14,4.141,8.307,5.875,11.686,5.875a7.5,7.5,0,0,0,5.418-2.061l.634-.634A3.513,3.513,0,0,0,27.308,20.649ZM25.894,24.2l-.634.634c-2.6,2.6-8.339,2.125-14.276-3.813S4.571,9.34,7.171,6.74L7.8,6.107a1.511,1.511,0,0,1,2.133,0l2.2,2.2a1.511,1.511,0,0,1,.021,2.11,4.181,4.181,0,0,0-.531,5.239,17.01,17.01,0,0,0,4.713,4.706,4.179,4.179,0,0,0,5.231-.517,1.512,1.512,0,0,1,2.118.013l2.2,2.2A1.51,1.51,0,0,1,25.894,24.2Z"></path></svg>
            <span>+7 (996) 644-27-98</span>
          </div>
        </nav>
      </header>
      <Slider1 />
      <div id="product"></div>
      <DynamicTitle title={"Товары дня"} subtitle={'Ограниченное время'} />
      <TopProducts />
      <div id="category"></div>
      <DynamicTitle title={"Категории товаров"} subtitle={'Выберите нужную категорию'} />
      <div className='window_cards'>
        {
          imgData.map(item => {
            return (
              <Card
              id={item.id}
              image={item.img}
              model={item.model}
              price={item.price}
              description={item.desc}
              onSelectItem={onSelectItem}
              />
            )
          })
        }

      </div>
      <div id="obaut"></div>
      <DynamicTitle title = {
        "О Нас"
      }/>
      <div className="about">
        <div className="about_text">
          Сеть магазинов Telecoop - сеть продаж мобильных телефонов в России.”Telecoop” работает с 2022 года.Благодаря своим честным и качественным услугам компания быстро завоевала доверие клиентов и за короткое время расширила географию своего развития.
        </div>
        <div className="about_img">
          <img src={mobile123} />
        </div>
      </div>
      <div id='rew'></div>
      <DynamicTitle title = {
        "Отзывы"
      }/>
      <Slider2 />
    <Footer />
      {
        open && <Modal onCloase={onCloase} info={selectedItem} />
      }
    </div>
  );
}

export default Main;
