import React from 'react';
import './DynamicTitle.scss';

const DynamicTitle = ({ title, subtitle }) => {
  return (
    <div className="dynamic-title">
      <h1 className="dynamic-title__main">{title}</h1>
      {subtitle && <p className="dynamic-title__subtitle">{subtitle}</p>}
    </div>
  );
};

export default DynamicTitle;