import React from 'react';
import './Card.scss';

const Card = ({ id, model, price, description, image, onSelectItem }) => {

  const onOpenModal = () => {
    onSelectItem(id)
  }

  return (
    <div className="card">
      <div className="card-image">
        <img src={image} alt={model} />
      </div>
      <div className="card-details">
        <h3 className="card-model">{model}</h3>
        <p className="card-price">{price} ₽</p>
        <h2 className="card-model">Есть в наличии</h2>
        <p className="card-description">{description}</p>
        <button onClick={onOpenModal} className="card-button">Купить</button>
      </div>
    </div>
  );
};

export default Card;